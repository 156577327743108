// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks")
require("@rails/activestorage").start()
require("jquery")
require("bootstrap")
require("bootstrap-table")
require("bootstrap-datepicker")
import Cookies from 'js.cookie';
window.Cookies = Cookies;
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// PEDU - sun nuova installazione questi moduli devono essere installati con YARN ADD
//- yarn add bootstrap jquery popper.js
//- yarn add bootstrap@next @popperjs/core
//- yarn add bootstrap-select bootstrap-table
//- yarn add bootstrap-datepicker

$(window).on('focus', function () {
    // Controllo quanto la pagina è VECCHIA e la ri carico
    console.log('Holder page: CHECK...');
    let dactual = new Date();
    let dpage = $('body').attr('nav-holder');
    if (typeof(dpage) != 'undefined') {
        let diff = (((dactual.getTime() / 1000).toFixed()) - dpage);
        // console.log('Holder page: ' + diff);
        // Se la pagina è più vecchia di 5 minuti aggiorno
        if (diff > (60 * 5)) {
            window.location.reload();
        }
    }
});
